import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import PublicationsGrid from 'components/publications';
import BREAKPOINTS from 'constants/breakpoints';
import withSize from 'hoc/withSize';

function getColumnWidth(screen) {
  if (screen <= BREAKPOINTS.SMALL) return '100%';
  if (screen <= BREAKPOINTS.MEDIUM) return '50%';
  return '33.33%';
}

const Publications = ({ pageContext: { publications }, width }) => {
  return (
    <Layout>
      <Head pageTitle="Publications" />
      <Box>
        <PublicationsGrid
          publications={publications}
          colWidth={getColumnWidth(width)}
          subheader="We have partnered with Horwath HTL to bring you the latest market reports"
        />
      </Box>
    </Layout>
  );
};

Publications.propTypes = {
  pageContext: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
};

export default withSize(Publications);
