import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { format } from 'timeago.js';
import _get from 'lodash.get';

import { Container } from './publication.css';

const Publication = ({ item }) => (
  <Container>
    <Link to={`/publications/${item.slug}`} className="nostyle">
      <div className="image">
        {_get(item, "_embedded['wp:featuredmedia']") && (
          <img
            src={_get(item, "_embedded['wp:featuredmedia'][0].source_url")}
            alt={_get(item, "_embedded['wp:featuredmedia'][0].alt_text")}
          />
        )}
      </div>
      <div className="content">
        <span
          className="title"
          dangerouslySetInnerHTML={{ __html: item.title.rendered }}
        />
        <span className="date">{format(new Date(item.date))}</span>
      </div>
    </Link>
  </Container>
);

Publication.propTypes = {
  item: PropTypes.object,
  seeMore: PropTypes.bool,
};

Publication.defaultProps = {
  item: null,
  seeMore: false,
};

export default Publication;
