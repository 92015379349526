import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './publications.css';
import Publication from './publication';
import StackGrid from 'react-stack-grid';
import withSize from 'hoc/withSize';
import BREAKPOINTS from 'constants/breakpoints';
import Header from 'components/header';

function getColumnWidth(screen) {
  if (screen <= BREAKPOINTS.SMALL) return '100%';
  if (screen <= BREAKPOINTS.MEDIUM) return '50%';
  if (screen <= BREAKPOINTS.LARGE) return '33.33%';
  if (screen <= BREAKPOINTS.LARGER) return '25%';
  return '20%';
}

const Publications = ({
  publications,
  width,
  colWidth,
  publicationsToDisplay,
  subheader,
}) => (
  <Container>
    <Header>Publications</Header>
    {!!subheader && <h4>{subheader}</h4>}
    <StackGrid
      columnWidth={colWidth || getColumnWidth(width)}
      itemComponent="div"
      gutterWidth={10}
      gutterHeight={10}
    >
      {publications.slice(0, publicationsToDisplay).map(item => (
        <Publication key={item.id} item={item} />
      ))}
    </StackGrid>
  </Container>
);

Publications.propTypes = {
  publications: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  colWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  publicationsToDisplay: PropTypes.number,
  seeMore: PropTypes.bool,
  subheader: PropTypes.string,
};

Publications.defaultProps = {
  colWidth: null,
  publicationsToDisplay: 100,
  seeMore: false,
  subheader: null,
};

export default withSize(Publications);
