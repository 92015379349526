import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
  &:hover {
    cursor: pointer;
    .title {
      text-decoration: underline;
      text-decoration-color: ${props => props.theme.colors.secondary};
    }
  }
  .image {
    display: flex;
    justify-content: center;
    background-color: ${props => props.theme.colors.secondaryLight4};
    overflow: hidden;
    img {
      height: 200px;
    }
  }
  .content {
    padding: ${props => props.theme.spacing.padding};
  }
  span {
    display: block;
  }
  .title {
    line-height: calc(${props => props.theme.font.size.medium} * 1.3);
    font-size: ${props => props.theme.font.size.medium};
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
  }
  .content {
    line-height: calc(${props => props.theme.font.size.small} * 1.5);
    font-size: ${props => props.theme.font.size.small};
    margin: 0;
    padding-top: calc(${props => props.theme.spacing.padding} / 2);
  }
  .date {
    font-size: ${props => props.theme.font.size.smaller};
  }
`;
